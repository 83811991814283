<template>
  <nav class="navbar px-md-0 navbar-expand-lg navbar-dark bg-primary">
    <div class="container-fluid px-md-5">
      <router-link
        :to="{ name: 'home'}"
        class="navbar-brand"
      >Adrenaline Finland</router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <i class="bi bi-list"></i>
      </button>

      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ml-auto">

          <li class="nav-item"><router-link
            :to="{ name: 'home'}"
            class="nav-link"
          >Etusivu</router-link></li>
          <li class="nav-item"><router-link
            :to="{ name: 'booking'}"
            class="nav-link"
          >Varauskalenteri</router-link></li>
          <li class="nav-item"><router-link
            :to="{ name: 'home', hash: '#contact'}"
            class="nav-link"
          >Yhteystiedot</router-link></li>

          <!-- <li class="nav-item"><a href="case.html" class="nav-link">Case Studies</a></li>
          <li class="nav-item"><a href="attorneys.html" class="nav-link">Attorneys</a></li>
          <li class="nav-item"><a href="blog.html" class="nav-link">Blog</a></li>
          <li class="nav-item"><a href="contact.html" class="nav-link">Contact</a></li> -->
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';

/* import Logout from "@/components/Logout";
import HomeIcon from "@/components/icons/HomeIcon";
import LoginIcon from "@/components/icons/LoginIcon"; */

export default {
  name: 'Header',
  components: {
    /* Logout,
    HomeIcon,
    LoginIcon, */
  },
  computed: {
    ...mapGetters('auth', ['authUser', 'isAdmin']),
  },
  methods: {

  }
};
</script>
