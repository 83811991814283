<template>
  <nav
    class="navbar navbar-expand-lg bg-dark navbar-light d-none d-lg-block"
    id="templatemo_nav_top"
  >
    <div class="container text-light">
      <div class="w-100 d-flex justify-content-between">
        <div>
          <i class="bi bi-envelope mx-2" style="font-size: 16px!important"></i>
          <a
            class="navbar-sm-brand text-light text-decoration-none"
            href="mailto:adrenalinefinland@gmail.com"
            >adrenalinefinland@gmail.com</a
          >
          <i class="bi bi-phone mx-2" style="font-size: 16px!important"></i>
          <a
            class="navbar-sm-brand text-light text-decoration-none"
            href="tel:+3580445889403"
            >+358 44 588 9403</a
          >
        </div>
        <div>
          <a
            class="text-light me-3"
            href="https://www.facebook.com/adrenalinefinland/"
            target="_blank"
            >
            <i class="bi bi-facebook" style="font-size: 16px!important"></i>
          </a>
          <a
            class="text-light me-3"
            href="https://www.instagram.com/adrenalinefinland"
            target="_blank"
            ><i class="bi bi-instagram" style="font-size: 16px!important"></i
          ></a>
          <!--
          <a class="text-light" href="https://twitter.com/" target="_blank"
            ><i class="fab fa-twitter fa-sm fa-fw me-2"></i
          ></a>
          <a class="text-light" href="https://www.linkedin.com/" target="_blank"
            ><i class="fab fa-linkedin fa-sm fa-fw"></i
          ></a> -->
        </div>
      </div>
    </div>
  </nav>
</template>

<style scoped>
#templatemo_nav_top { min-height: 40px;}
#templatemo_nav_top * { font-size: .9em !important;}
#templatemo_main_nav a { color: #212934;}
#templatemo_main_nav a:hover { color: #69bb7e;}
#templatemo_main_nav .navbar .nav-icon { margin-right: 20px;}

</style>
