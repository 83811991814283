{
  "message": "hello i18n !!",
  "global": {
    "new": "Uusi",
    "cancel": "Peruuta",
    "export": "Vie",
    "records-empty": "Ei tuloksia",
    "records-loading": "Tietoja ladataan",
    "search": "Etsi...",
    "archive": "Arkistoi",
    "restore": "Palauta",
    "delete": "Poista",
    "save": "Tallenna",
    "close": "Sulje",
    "edit": "Muokkaa",
    "download": "Lataa",
    "db" : {
      "id": "ID",
      "name": "Nimi",
      "start": "Alku",
      "end": "Loppu",
      "description": "Selite",
      "deleted_at": "Arkistoitu",
      "created_at": "Luotu",
      "updated_at": "Päivitetty"
    }
  },
  "vuelidate": {
    "required": "Arvo vaaditaan",
    "email": "Sähköpostiosoite ei ole kelvollinen",
    "validDate": "Arvon tulee olla päivämäärä muodossa dd.mm.yyyy",
    "decimal": "Arvon tulee olla desimaali",
    "maxValue": "Arvon tulee olla enintään {value}",
    "maxLength": "Arvon tulee olla enintään {value} pitkä",
    "minLength": "Arvon tulee olla vähintään {value} pitkä"
  }
}
