<template>
  <div id="app">
    <TopNav />
    <Header />
    <router-view />
  </div>
</template>

<script>
import TopNav from '@/components/TopNav';
import Header from '@/components/Header';


export default {
  name: 'app',
  components: {
    Header,
    TopNav,
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/main';

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;700;900&display=swap");

</style>
