import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import i18n from './i18n';

import VCalendar from 'v-calendar';
import { VueReCaptcha } from 'vue-recaptcha-v3';

import 'bootstrap';
/* import 'bootstrap/js/src/collapse';
import 'bootstrap/js/src/carousel';
import 'bootstrap/js/src/popover'; */
/* import { Modal, Carousel } from 'bootstrap'; */

/* import '@/assets/scss/main.scss'; */

const app = createApp(App);
app.use(router);
app.use(store);
app.use(i18n);
app.use(VueReCaptcha, { siteKey: '6Lf40d4aAAAAAPaYkxGYAQWGNgdj5VuBmaER5P4W' });

app.use(VCalendar, {
  locales: {
    'fi-FI': {
      firstDayOfWeek: 2,
      monthNames: ['Tammikuu', 'Helmikuu', 'Maaliskuu', 'Huhtikuu', 'Toukokuu', 'Kesäkuu', 'Heinäkuu', 'Elokuu', 'Syyskuu', 'Lokakuu', 'Marraskuu', 'Joulukuu'],
      monthNamesShort: ['Tam', 'Hel', 'Maa', 'Huh', 'Tou', 'Kes', 'Hei', 'Elo', 'Syy', 'Lok', 'Mar', 'Jou'],
    },
  },
});

app.mount('#app');
