import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store/index';
import auth from '@/middleware/auth';
/* import admin from '@/middleware/admin'; */
import guest from '@/middleware/guest';
import middlewarePipeline from '@/router/middlewarePipeline';

const routes = [
  {
    path: '/',
    name: 'homelayout',
    meta: { middleware: [guest] },
    component: () => import(/* webpackChunkName: "home" */ '@/views/HomeLayout'),
    redirect: { name: 'home' },
    children: [
      {
        path: '',
        name: 'home',
        meta: { scrollToTop: true, middleware: [guest] },
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home'),
      },
      {
        path: 'booking',
        name: 'booking',
        meta: { middleware: [guest] },
        component: () => import(/* webpackChunkName: "home" */ '@/views/Booking'),
      },
      {
        path: 'contact',
        name: 'contact',
        meta: { middleware: [guest] },
        component: () => import(/* webpackChunkName: "home" */ '@/views/Contact'),
      },
    ]
  },
  {
    path: '/manager',
    name: 'manager',
    meta: { middleware: [auth] },
    component: () => import(/* webpackChunkName: "manager" */ '@/views/Manager/ManagerLayout'),
    redirect: { name: 'manager.dashboard' },
    children: [
      {
        path: 'dashboard',
        name: 'manager.dashboard',
        meta: {
          title: 'Adrenaline Finland',
          middleware: [auth]
        },
        component: () =>
          import(/* webpackChunkName: "manager" */ '@/views/Manager/Dashboard'),
      },
      {
        path: 'user',
        name: 'manager.user',
        meta: { middleware: [auth] },
        component: () => import(/* webpackChunkName: "manager" */ '@/views/Manager/User'),
      },
      {
        path: 'rentals',
        name: 'manager.rentals',
        meta: { middleware: [auth] },
        component: () => import(/* webpackChunkName: "manager" */ '@/views/Manager/rentals/Rentals'),
      },
      {
        path: 'rentaltimes',
        name: 'manager.rentaltimes',
        meta: { middleware: [auth] },
        component: () => import(/* webpackChunkName: "manager" */ '@/views/Manager/rentals/RentalTimes'),
      },
      {
        path: 'bookings',
        name: 'manager.bookings',
        meta: { middleware: [auth] },
        component: () => import(/* webpackChunkName: "manager" */ '@/views/Manager/bookings/Bookings'),
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    meta: { middleware: [guest] },
    component: () => import(/* webpackChunkName: "login" */ '../views/Login'),
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    meta: { middleware: [guest] },
    component: () =>
      import(/* webpackChunkName: "reset-password" */ '../views/ResetPassword'),
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    meta: { middleware: [guest] },
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ '../views/ForgotPassword'
      ),
  },
  {
    path: '/:catchAll(.*)',
    name: 'notFound',
    component: () =>
      import(/* webpackChunkName: "not-found" */ '../views/NotFound'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash) {
      return {
        el: to.hash,
      };
    }

    return { top: 0 };
  },
  routes
});

router.beforeEach((to, from, next) => {
  const middleware = to.meta.middleware;
  const context = { to, from, next, store };

  if (!middleware) {
    return next();
  }

  middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router;
